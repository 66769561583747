<template>
  <div class="wrap">
    <div class="wrap-header">
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/afsa-data' }"><a>Load AFSA Data </a></el-breadcrumb-item>
        <el-breadcrumb-item>Query Result</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="wrap-header-btn">
        <el-button type="primary" plain size="small" class="public-fonts" @click="query()">Query</el-button>
        <el-button type="primary" plain size="small" class="public-button-color public-fonts" @click="byAwbQuery('by')">Load data from AFSA</el-button>
      </div>
    </div>
    <div class="wrap-content">
      <el-table
        :data="this.$store.state.queryResultAFsa.datas"
        class="content-table">
        <el-table-column
          prop="awb_no"
          label="AWB#"
          label-class-name="title">
        </el-table-column>
        <el-table-column
          prop="mawb_no"
          label="MAWB#"
          label-class-name="title">
        </el-table-column>
        <el-table-column
          prop="origin"
          label="Origin"
          label-class-name="title">
        </el-table-column>
        <el-table-column
          prop="dest"
          label="Dest."
          label-class-name="title">
        </el-table-column>
        <el-table-column
          label="Flight Date"
          label-class-name="title">
          <template slot-scope="scope">
            <span>{{ scope.row.loc_flt_datetime }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="load_date"
          label="Data Load Time(GMT)"
          label-class-name="title">
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="this.$store.state.queryResultAFsa.currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="this.$store.state.queryResultAFsa.pageSize"
          layout="total, prev, slot, next, sizes, jumper, ->"
          :total="this.$store.state.queryResultAFsa.totalNum">
          <span class="on-pager">{{ this.$store.state.queryResultAFsa.currentPage }}</span>
      </el-pagination>
    </div>
    <el-drawer
      :title="flagQuery !== null ? 'Load Data Criteria':'Query Criteria'"
      :visible.sync="querydrawer"
      :show-close="false"
      :direction="direction"
      :size="332">
      <ByAwbQuery v-if="flagQuery !== null" @awbResultdrawer="awbResultdrawer"></ByAwbQuery>
      <Query v-else @query-drawer="changeQuerydrawer"></Query>
    </el-drawer>
  </div>
</template>

<script>
import Query from '../afsa-data/query.vue'
import ByAwbQuery from '../afsa-data/by-awb-query.vue'
import { getFasaData } from '../../../api/afsa-data/afsa-api'

export default {
  name: 'qurey-result',
  data () {
    return {
      querydrawer: false, // 查询抽屉
      direction: 'rtl', // 查询抽屉弹出的方向
      flagQuery: null,
      fromPath: '/afsa-data'
    }
  },
  components: {
    Query,
    ByAwbQuery
  },
  mounted () {
    if (this.fromPath === '/afsa-data/qurey-result') {
      this.init()
    } else if (this.$route.path === '/afsa-data/qurey-result' && this.fromPath === '/') {
      this.init()
    }
  },
  methods: {
    init () {
      const obj = {
        data: {
          officeCode: this.$route.query.officeCode,
          awbNo: this.$route.query.awbNo,
          mawbNo: this.$route.query.mawbNo,
          origin: this.$route.query.origin,
          dest: this.$route.query.dest,
          flightDateFrom: this.$route.query.flightDateFrom,
          flightDateTo: this.$route.query.flightDateTo,
          executionDateFrom: this.$route.query.executionDateFrom,
          executionDateTo: this.$route.query.executionDateTo,
          createDateFrom: this.$route.query.createDateFrom,
          createDateTo: this.$route.query.createDateTo,
          pageSize: 10,
          currentPage: 1
        },
        loading: true
      }
      getFasaData(obj).then(res => {
        if (res.data.CODE === 200) {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: 'Query Successfully!',
            type: 'success',
            showClose: true,
            duration: 8000,
            offset: 120
          })
          this.$store.dispatch('changequeryResultAFsa', res.data.DATA)
        } else {
          if (res.data.MESSAGE) {
            this.$message({
              dangerouslyUseHTMLString: true,
              message: `${res.data.MESSAGE}`,
              type: 'error',
              showClose: true,
              duration: 8000,
              offset: 120
            })
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    sizeChange (val) {
      const obj = {
        data: {
          officeCode: this.$route.query.officeCode,
          awbNo: this.$route.query.awbNo,
          mawbNo: this.$route.query.mawbNo,
          origin: this.$route.query.origin,
          dest: this.$route.query.dest,
          flightDateFrom: this.$route.query.flightDateFrom,
          flightDateTo: this.$route.query.flightDateTo,
          executionDateFrom: this.$route.query.executionDateFrom,
          executionDateTo: this.$route.query.executionDateTo,
          createDateFrom: this.$route.query.createDateFrom,
          createDateTo: this.$route.query.createDateTo,
          pageSize: val,
          currentPage: this.$store.state.queryResultAFsa.currentPage
        },
        loading: true
      }
      getFasaData(obj).then(res => {
        if (res.data.CODE === 200) {
          this.$store.dispatch('changequeryResultAFsa', res.data.DATA)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    currentChange (val) {
      const obj = {
        data: {
          officeCode: this.$route.query.officeCode,
          awbNo: this.$route.query.awbNo,
          mawbNo: this.$route.query.mawbNo,
          origin: this.$route.query.origin,
          dest: this.$route.query.dest,
          flightDateFrom: this.$route.query.flightDateFrom,
          flightDateTo: this.$route.query.flightDateTo,
          executionDateFrom: this.$route.query.executionDateFrom,
          executionDateTo: this.$route.query.executionDateTo,
          createDateFrom: this.$route.query.createDateFrom,
          createDateTo: this.$route.query.createDateTo,
          pageSize: this.$store.state.queryResultAFsa.pageSize,
          currentPage: val
        },
        loading: true
      }
      getFasaData(obj).then(res => {
        if (res.data.CODE === 200) {
          this.$store.dispatch('changequeryResultAFsa', res.data.DATA)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    query () {
      this.flagQuery = null
      this.querydrawer = true
    },
    changeQuerydrawer (data) {
      this.querydrawer = data.close
    },
    byAwbQuery (flag) {
      this.flagQuery = flag
      this.querydrawer = true
    },
    awbResultdrawer (data) {
      this.querydrawer = data.close
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例,将值传入fromPath
      vm.fromPath = from.path
    })
  }
}
</script>

<style lang="scss" scoped>
$bg1:#1890ff;
.wrap {
  .wrap-header {
    display: flex;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    .font-breadcrumb{
      color: #303133 !important;
      font-weight: 700 !important;
    }
    ::v-deep {
      .el-breadcrumb__inner{
        color: #303133;
      }
    }
  }
  .wrap-content{
    padding: 5px 24px 24px;
    min-height: 72vh;
    .content-table{
      width: 100%;
      border-radius: 8px;
    }
    ::v-deep {
       .title{
        color: #000;
        font-size: 16px;
      }
    }
  }
}
</style>
